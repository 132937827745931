.App {
  text-align: center;
  }
  
  .App-logo {
  /* /_ Comment out the line below _/
  /_ animation: App-logo-spin infinite 20s linear; _/
  height: 40vmin;
  pointer-events: none; */
  }
  
  .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  }
  
  .App-link {
  color: #61dafb;
  }




  
  div {
    display:inline-block;
    overflow:hidden;
    white-space:nowrap;
  }
  
  div:content {   
    opacity:0;
  }
  
  div:head {
    width:0px;
  
  }
  
  div:subhead span {
    margin-left:-355;
   
  }
  
  /* /_ Commment out the code below _/ */
  /* /_ @keyframes App-logo-spin {
  from {
  transform: rotate(0deg);
  }
  to {
  transform: rotate(360deg);
  }
  } _/ */